exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-uk-js": () => import("./../../../src/pages/about.uk.js" /* webpackChunkName: "component---src-pages-about-uk-js" */),
  "component---src-pages-about-us-editorial-team-uk-js": () => import("./../../../src/pages/about-us/editorial-team.uk.js" /* webpackChunkName: "component---src-pages-about-us-editorial-team-uk-js" */),
  "component---src-pages-bewertungen-de-js": () => import("./../../../src/pages/bewertungen.de.js" /* webpackChunkName: "component---src-pages-bewertungen-de-js" */),
  "component---src-pages-blackfriday-js": () => import("./../../../src/pages/blackfriday.js" /* webpackChunkName: "component---src-pages-blackfriday-js" */),
  "component---src-pages-blackfriday-success-js": () => import("./../../../src/pages/blackfriday-success.js" /* webpackChunkName: "component---src-pages-blackfriday-success-js" */),
  "component---src-pages-calculator-de-colagen-ro-js": () => import("./../../../src/pages/calculator-de-colagen.ro.js" /* webpackChunkName: "component---src-pages-calculator-de-colagen-ro-js" */),
  "component---src-pages-collagen-calculator-uk-js": () => import("./../../../src/pages/collagen-calculator.uk.js" /* webpackChunkName: "component---src-pages-collagen-calculator-uk-js" */),
  "component---src-pages-contact-ro-js": () => import("./../../../src/pages/contact.ro.js" /* webpackChunkName: "component---src-pages-contact-ro-js" */),
  "component---src-pages-contact-uk-js": () => import("./../../../src/pages/contact.uk.js" /* webpackChunkName: "component---src-pages-contact-uk-js" */),
  "component---src-pages-cz-404-js": () => import("./../../../src/pages/cz/404.js" /* webpackChunkName: "component---src-pages-cz-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-despre-noi-redactie-ro-js": () => import("./../../../src/pages/despre-noi/redactie.ro.js" /* webpackChunkName: "component---src-pages-despre-noi-redactie-ro-js" */),
  "component---src-pages-despre-noi-ro-js": () => import("./../../../src/pages/despre-noi.ro.js" /* webpackChunkName: "component---src-pages-despre-noi-ro-js" */),
  "component---src-pages-index-bg-js": () => import("./../../../src/pages/index.bg.js" /* webpackChunkName: "component---src-pages-index-bg-js" */),
  "component---src-pages-index-cz-js": () => import("./../../../src/pages/index.cz.js" /* webpackChunkName: "component---src-pages-index-cz-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-hu-js": () => import("./../../../src/pages/index.hu.js" /* webpackChunkName: "component---src-pages-index-hu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-index-ro-js": () => import("./../../../src/pages/index.ro.js" /* webpackChunkName: "component---src-pages-index-ro-js" */),
  "component---src-pages-index-sk-js": () => import("./../../../src/pages/index.sk.js" /* webpackChunkName: "component---src-pages-index-sk-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-index-uk-js": () => import("./../../../src/pages/index.uk.js" /* webpackChunkName: "component---src-pages-index-uk-js" */),
  "component---src-pages-kalkulacka-kolagen-cz-js": () => import("./../../../src/pages/kalkulacka-kolagen.cz.js" /* webpackChunkName: "component---src-pages-kalkulacka-kolagen-cz-js" */),
  "component---src-pages-kalkulator-kolagenu-pl-js": () => import("./../../../src/pages/kalkulator-kolagenu.pl.js" /* webpackChunkName: "component---src-pages-kalkulator-kolagenu-pl-js" */),
  "component---src-pages-kalkulator-za-kolagen-bg-js": () => import("./../../../src/pages/kalkulator-za-kolagen.bg.js" /* webpackChunkName: "component---src-pages-kalkulator-za-kolagen-bg-js" */),
  "component---src-pages-kalkulyator-kolagenu-ua-js": () => import("./../../../src/pages/kalkulyator-kolagenu.ua.js" /* webpackChunkName: "component---src-pages-kalkulyator-kolagenu-ua-js" */),
  "component---src-pages-kapcsolat-hu-js": () => import("./../../../src/pages/kapcsolat.hu.js" /* webpackChunkName: "component---src-pages-kapcsolat-hu-js" */),
  "component---src-pages-kolagenova-kalkulacka-sk-js": () => import("./../../../src/pages/kolagenova-kalkulacka.sk.js" /* webpackChunkName: "component---src-pages-kolagenova-kalkulacka-sk-js" */),
  "component---src-pages-kollagen-kalkulator-hu-js": () => import("./../../../src/pages/kollagen-kalkulator.hu.js" /* webpackChunkName: "component---src-pages-kollagen-kalkulator-hu-js" */),
  "component---src-pages-kollagen-rechner-de-js": () => import("./../../../src/pages/kollagen-rechner.de.js" /* webpackChunkName: "component---src-pages-kollagen-rechner-de-js" */),
  "component---src-pages-kontakt-bg-js": () => import("./../../../src/pages/kontakt.bg.js" /* webpackChunkName: "component---src-pages-kontakt-bg-js" */),
  "component---src-pages-kontakt-cz-js": () => import("./../../../src/pages/kontakt.cz.js" /* webpackChunkName: "component---src-pages-kontakt-cz-js" */),
  "component---src-pages-kontakt-de-js": () => import("./../../../src/pages/kontakt.de.js" /* webpackChunkName: "component---src-pages-kontakt-de-js" */),
  "component---src-pages-kontakt-pl-js": () => import("./../../../src/pages/kontakt.pl.js" /* webpackChunkName: "component---src-pages-kontakt-pl-js" */),
  "component---src-pages-kontakt-sk-js": () => import("./../../../src/pages/kontakt.sk.js" /* webpackChunkName: "component---src-pages-kontakt-sk-js" */),
  "component---src-pages-kontakt-ua-js": () => import("./../../../src/pages/kontakt.ua.js" /* webpackChunkName: "component---src-pages-kontakt-ua-js" */),
  "component---src-pages-nazory-sk-js": () => import("./../../../src/pages/nazory.sk.js" /* webpackChunkName: "component---src-pages-nazory-sk-js" */),
  "component---src-pages-o-nas-cz-js": () => import("./../../../src/pages/o-nas.cz.js" /* webpackChunkName: "component---src-pages-o-nas-cz-js" */),
  "component---src-pages-o-nas-pl-js": () => import("./../../../src/pages/o-nas.pl.js" /* webpackChunkName: "component---src-pages-o-nas-pl-js" */),
  "component---src-pages-o-nas-redakce-cz-js": () => import("./../../../src/pages/o-nas/redakce.cz.js" /* webpackChunkName: "component---src-pages-o-nas-redakce-cz-js" */),
  "component---src-pages-o-nas-redakcia-sk-js": () => import("./../../../src/pages/o-nas/redakcia.sk.js" /* webpackChunkName: "component---src-pages-o-nas-redakcia-sk-js" */),
  "component---src-pages-o-nas-redakcja-pl-js": () => import("./../../../src/pages/o-nas/redakcja.pl.js" /* webpackChunkName: "component---src-pages-o-nas-redakcja-pl-js" */),
  "component---src-pages-o-nas-sk-js": () => import("./../../../src/pages/o-nas.sk.js" /* webpackChunkName: "component---src-pages-o-nas-sk-js" */),
  "component---src-pages-opinie-pl-js": () => import("./../../../src/pages/opinie.pl.js" /* webpackChunkName: "component---src-pages-opinie-pl-js" */),
  "component---src-pages-opinii-ro-js": () => import("./../../../src/pages/opinii.ro.js" /* webpackChunkName: "component---src-pages-opinii-ro-js" */),
  "component---src-pages-pregledi-bg-js": () => import("./../../../src/pages/pregledi.bg.js" /* webpackChunkName: "component---src-pages-pregledi-bg-js" */),
  "component---src-pages-pro-nas-redaktsiya-ua-js": () => import("./../../../src/pages/pro-nas/redaktsiya.ua.js" /* webpackChunkName: "component---src-pages-pro-nas-redaktsiya-ua-js" */),
  "component---src-pages-pro-nas-ua-js": () => import("./../../../src/pages/pro-nas.ua.js" /* webpackChunkName: "component---src-pages-pro-nas-ua-js" */),
  "component---src-pages-recenze-cz-js": () => import("./../../../src/pages/recenze.cz.js" /* webpackChunkName: "component---src-pages-recenze-cz-js" */),
  "component---src-pages-reviews-uk-js": () => import("./../../../src/pages/reviews.uk.js" /* webpackChunkName: "component---src-pages-reviews-uk-js" */),
  "component---src-pages-rolunk-hu-js": () => import("./../../../src/pages/rolunk.hu.js" /* webpackChunkName: "component---src-pages-rolunk-hu-js" */),
  "component---src-pages-rolunk-szerkesztoseg-hu-js": () => import("./../../../src/pages/rolunk/szerkesztoseg.hu.js" /* webpackChunkName: "component---src-pages-rolunk-szerkesztoseg-hu-js" */),
  "component---src-pages-ua-404-js": () => import("./../../../src/pages/ua/404.js" /* webpackChunkName: "component---src-pages-ua-404-js" */),
  "component---src-pages-uber-uns-de-js": () => import("./../../../src/pages/uber-uns.de.js" /* webpackChunkName: "component---src-pages-uber-uns-de-js" */),
  "component---src-pages-uber-uns-redaktion-de-js": () => import("./../../../src/pages/uber-uns/redaktion.de.js" /* webpackChunkName: "component---src-pages-uber-uns-redaktion-de-js" */),
  "component---src-pages-uk-404-js": () => import("./../../../src/pages/uk/404.js" /* webpackChunkName: "component---src-pages-uk-404-js" */),
  "component---src-pages-velemenyek-hu-js": () => import("./../../../src/pages/velemenyek.hu.js" /* webpackChunkName: "component---src-pages-velemenyek-hu-js" */),
  "component---src-pages-vidhuky-ua-js": () => import("./../../../src/pages/vidhuky.ua.js" /* webpackChunkName: "component---src-pages-vidhuky-ua-js" */),
  "component---src-pages-w-kalkulator-kolagenowy-js": () => import("./../../../src/pages/w/kalkulator-kolagenowy.js" /* webpackChunkName: "component---src-pages-w-kalkulator-kolagenowy-js" */),
  "component---src-pages-za-nas-bg-js": () => import("./../../../src/pages/za-nas.bg.js" /* webpackChunkName: "component---src-pages-za-nas-bg-js" */),
  "component---src-pages-za-nas-redakcia-bg-js": () => import("./../../../src/pages/za-nas/redakcia.bg.js" /* webpackChunkName: "component---src-pages-za-nas-redakcia-bg-js" */),
  "component---src-templates-author-page-author-page-js": () => import("./../../../src/templates/AuthorPage/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-author-page-js" */),
  "component---src-templates-blog-pages-blog-list-js": () => import("./../../../src/templates/Blog/pages/BlogList.js" /* webpackChunkName: "component---src-templates-blog-pages-blog-list-js" */),
  "component---src-templates-blog-pages-blog-post-js": () => import("./../../../src/templates/Blog/pages/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-pages-blog-post-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */)
}

